export default function (Component) {
  Component.__i18nGlobal = Component.__i18nGlobal || []
  Component.__i18nGlobal.push({
    "locale": "",
    "resource": {
      "de": {
        "book_format": {
          "name": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
            "epub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPUB"])},
            "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
            "audiobook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiobook"])},
            "mp3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiobook"])},
            "epub_fixed_layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPUB (fixed layout)"])}
          }
        },
        "offers": {
          "other_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other licenses for this publication"])},
          "incompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your lending portal is incompatible with this publication"])},
          "previous_purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more licenses for this book have been acquired by your organization"])},
          "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This book is included in the shared collection"])},
          "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
          "more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
          "fewer_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disable"])},
          "disable_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to disable this offer? This operation cannot be undone. This offer might also be reactivated in a future importation."])},
          "admin": {
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove item from cart"])},
            "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry ordering this item"])},
            "current_price": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current offer price: ", _interpolate(_named("price"))])},
            "new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New price"])},
            "update_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
            "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable offer"])}
          },
          "carts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
            "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
            "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended cart"])},
            "disclaimer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These recommendations are made by"])},
            "disclaimer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They can include **popular books in your library** whose license needs to be renewed, **books that are not in your catalog** that might interest readers based on their preferences, books **popular in other libraries**, but not in your catalog."])},
            "banner_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 easy ways to improve your catalog"])},
            "banner_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover carts already created to make your life easier. The books in these carts are:"])},
            "banner_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. The most popular in your library whose license needs to be renewed"])},
            "banner_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Absent from your catalog that might interest your readers based on their preferences"])},
            "banner_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Popular in other libraries, but are not in your catalog"])},
            "customer_service_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service cart"])},
            "customer_service_text_html": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This cart was validated by our Customer Success team. ", _interpolate(_named("br")), " Copies for all titles below are available in your catalog but will not be invoiced to your organization."])}
          },
          "cart_orders": {
            "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
            "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
            "distributor_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer distributor"])},
            "formatted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatted"])},
            "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table identifier"])},
            "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order identifier"])},
            "provider_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer provider"])},
            "provider_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication provider"])},
            "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query parameters"])},
            "raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw"])},
            "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library cart orders"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
            "url_unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown URL"])}
          }
        },
        "cart": {
          "refund": {
            "reason": {
              "faulty_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faulty files"])},
              "ordered_for_the_wrong_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered for the wrong library"])},
              "technical_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical issue"])},
              "operational_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational error"])},
              "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])}
            },
            "select_a_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a reason"])},
            "refund_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund mode"])},
            "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
            "button_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
            "confirm_window": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm refund"])},
              "table": {
                "unit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit price"])},
                "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
                "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
                "refund_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund amount"])},
                "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])}
              },
              "form": {
                "label_adicional_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details to include in client notification email (optional)"])},
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant context for clients (library, network, retailer, etc.)"])},
                "button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
                "button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm refund"])}
              }
            },
            "refund_copies": {
              "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Refunded Copy"]), _normalize(["Refunded Copies"])])},
              "message_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download refund summary"])},
              "date_of_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of refund"])}
            },
            "refund_cofirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund confirmed"])}
          },
          "confirm_validation": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As all sales are **final and non-refundable**, please take a moment to make sure this cart perfectly matches what you want to buy."])},
            "particularly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particularly"])},
            "list": {
              "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prices"])},
              "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantities"])},
              "formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formats"])},
              "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["license types"])}
            },
            "checkbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand and confirm that all items in my order are in accordance with my intent to purchase."])}
          },
          "license": {
            "no_other_license_type_is_available_for_this_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other license type is currently available for this publication"])},
            "select_the_desired_license_type_and_click_the_replace_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the desired license type and click the Replace button"])},
            "see_other_available_license_types_for_this_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See other available license types for this publication"])}
          },
          "move_cart": {
            "available_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available libraries"])},
            "available_library_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library patrons"])},
            "confirm_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to move these items to this cart?"])},
            "manage_users": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no users associated to this library"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage users"])}
            },
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to another library"])}
          },
          "retailer": {
            "retailers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retailers"])}
          },
          "sub_library": {
            "sub_libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced invoicing"])}
          },
          "item_status": {
            "already_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer already purchased"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
            "in_another_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer already present in another cart"])},
            "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded"])},
            "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated"])}
          },
          "unavailable_offers": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one title in your cart is  no longer available: its sales' conditions have been changed since you've added it to your cart."])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please delete all these titles from your cart to proceed with the validation of the cart:"])},
            "delete_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete these titles"])},
            "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry for the inconvenience."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important information about your cart"])},
            "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
            "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])}
          },
          "incompatible_offers": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service carts may only contain titles coming from a source operated by De Marque."])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must remove the following titles from the cart before it can be validated:"])},
            "delete_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete these titles"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart validation failed"])}
          },
          "loans": {
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 loan"])},
            "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " loans"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited loans"])},
            "loan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 loan"]), _normalize([_interpolate(_named("count")), " loans"])])}
          },
          "loans_concurrent_users": {
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One at a time"])},
            "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Concurrent loans"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])}
          },
          "loan_rules": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loans rules"])},
            "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan count"])},
            "concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurrent users"])},
            "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License duration"])},
            "max_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan maximum duration"])}
          },
          "loans_lifetime": {
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])}
          },
          "lifetime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
            "name": {
              "one_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 week"])},
              "one_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month"])},
              "two_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 months"])},
              "one_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 year"])},
              "two_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 years"])},
              "two_to_five_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-5 years"])},
              "five_to_ten_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-10 years"])},
              "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])}
            }
          },
          "datetime": {
            "distance_in_words": {
              "half_a_minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Half a minute"])},
              "less_than_x_seconds": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less than 1 second"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["less than ", _interpolate(_named("count")), " seconds"])}
              },
              "x_seconds": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 second"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " seconds"])}
              },
              "less_than_x_minutes": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less than 1 minute"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["less than ", _interpolate(_named("count")), " minutes"])}
              },
              "x_minutes": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 minute"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " minutes"])}
              },
              "about_x_hours": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about 1 hour"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["about ", _interpolate(_named("count")), " hours"])}
              },
              "x_days": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 day"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " days"])}
              },
              "about_x_months": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about 1 month"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["about ", _interpolate(_named("count")), " months"])}
              },
              "x_months": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " months"])}
              },
              "about_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about 1 year"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["about ", _interpolate(_named("count")), " years"])}
              },
              "over_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more than 1 year"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["more than ", _interpolate(_named("count")), " years"])}
              },
              "almost_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["almost 1 year"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["almost ", _interpolate(_named("count")), " years"])}
              },
              "x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 year"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " years"])}
              },
              "x_years_or_more": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 year or more"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " years or more"])}
              },
              "x_years_and_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " years and ", _interpolate(_named("days")), " days"])}
            }
          },
          "status": {
            "already_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer already purchased"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
            "in_another_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer already present in another cart"])},
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
            "to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
            "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated"])}
          },
          "copies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("copies")), " copies"])},
          "lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("lines")), " lines"])},
          "copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("copies")), " copy"])},
          "line": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("lines")), " line"])},
          "cart_item": {
            "status": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
              "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
              "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
              "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
              "to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated"])}
            }
          },
          "total_with_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total incl. all taxes"])},
          "cart_validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate cart"])},
          "back_button_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My carts"])},
          "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
          "cart_destroy_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this cart?"])},
          "cart_reprocess_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reprocess this cart?"])},
          "actions": {
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete cart"])},
            "delete_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
            "export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to CSV format"])},
            "export_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to Excel format"])},
            "import_isbns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import ISBNs"])},
            "move_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
            "move_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
            "reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocess cart"])},
            "duplicate_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate cart"])},
            "resend_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend confirmation email"])},
            "show_processing_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show processing logs"])},
            "process_a_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process a refund"])}
          },
          "paper_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper ISBN:"])},
          "offer_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer ISBN:"])},
          "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
          "offer": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])}
          },
          "form": {
            "move_items": {
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected offers have been moved to the following cart:"])},
              "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected offers have been copied to the following cart:"])}
            },
            "destination_cart_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New cart"])},
            "current_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing carts"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
            "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
            "libraries_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library carts"])},
            "my_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My carts"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
          },
          "header": {
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
            "move_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
            "copy_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
            "select_destination_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a destination cart"])},
            "sort_by_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
            "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the selected items?"])},
            "labels": {
              "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book / offer"])},
              "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit price"])},
              "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
              "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release date"])},
              "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
              "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price"])}
            },
            "sort": {
              "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
              "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
              "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release date"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
            }
          },
          "cart_item_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
          "cart_item_details_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer details"])},
          "cart_item_details_modal_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
          "cart_item_details_modal_file_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type"])},
          "cart_item_details_modal_loan_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan rules"])},
          "cart_item_details_modal_loan_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan count"])},
          "cart_item_details_modal_concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurrent users"])},
          "cart_item_details_modal_license_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License duration"])},
          "cart_item_details_modal_loan_maximum_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan maximum duration"])},
          "cart_item_details_modal_streaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming"])},
          "cart_item_details_modal_streaming_concurrent_users_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurrent users count"])},
          "cart_item_details_modal_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection"])},
          "cart_item_details_modal_protection_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection type"])},
          "cart_item_details_modal_authorized_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized devices"])},
          "cart_item_details_modal_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy/paste"])},
          "cart_item_details_modal_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
          "there_is_no_offer_in_this_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no items in your cart."])},
          "carts": {
            "group_purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This book is included in the shared collection"])},
            "list_items": {
              "ajax_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, something went wrong. We will reload this page so you can try again."])},
              "confirm_delete_titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete these titles?"])},
              "one_title_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one title to delete"])}
            },
            "lists": {
              "titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titles"])}
            },
            "currencies": {
              "CAD": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CAD ", _interpolate(_named("amount"))])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAD"])}
              },
              "CHF": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CHF ", _interpolate(_named("amount"))])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHF"])}
              },
              "EUR": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" € ", _interpolate(_named("amount"))])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])}
              },
              "GBP": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["£ ", _interpolate(_named("amount"))])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["£"])}
              },
              "USD": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$ ", _interpolate(_named("amount"))])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])}
              }
            },
            "details": {
              "loans_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 loan"])},
              "loans_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " loans"])},
              "loans_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited loans"])},
              "loans_concurrent_users_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One at a time"])},
              "loans_concurrent_users_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Concurrent loans"])},
              "loans_concurrent_users_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])},
              "loans_lifetime_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited lifetime"])},
              "x_days_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 day"])},
              "x_days_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " days"])},
              "x_years_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 year"])},
              "x_years_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " years"])},
              "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["False"])},
              "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])}
            },
            "service_fees": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Access and lock fees for digital books and digital audio (", _interpolate(_named("rate")), "%)"])},
            "sub_total_without_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-total without taxes"])},
            "total_by_currency": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total ", _interpolate(_named("currency"))])},
            "total_without_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total without taxes"])},
            "tax_rates": {
              "gst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GST"])},
              "hst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HST"])},
              "pst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PST"])},
              "qst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QST"])},
              "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])}
            }
          }
        },
        "not_found": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for does not exist."])}
        },
        "components": {
          "filtrable_select": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select option"])},
            "tag_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to create a tag"])},
            "select_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to select"])},
            "select_group_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to select group"])},
            "selected_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
            "deselect_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to remove"])},
            "deselect_group_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to deselect group"])}
          },
          "catalog_publication": {
            "availabilities": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
              "libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothekskatalog"])},
              "customize_availability_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passen Sie die Verfügbarkeitseinstellungen an"])},
              "networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerke"])},
              "territories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territorien"])},
              "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Märkte"])},
              "connectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschlüsse"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])}
            },
            "filters": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
              "publication_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichungsdatum"])},
              "catalog_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalogdatum"])},
              "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
              "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
              "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
              "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verfügbar"])},
              "unavailable__publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
              "unavailable__banned_not_epub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned (not EPUB)"])},
              "unavailable__banned_publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned publisher"])},
              "unavailable__date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
              "unavailable__cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover"])},
              "unavailable__duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
              "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
              "catalogued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalogisiert?"])},
              "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
              "influence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfluss"])},
              "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zielgruppe"])},
              "title_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel und Untertitel"])},
              "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
              "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlag"])},
              "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung"])},
              "type_to_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippe um zu suchen"])},
              "save_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen speichern"])},
              "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])},
              "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
              "option_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
              "option_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
              "pick_several_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrfachauswahl"])},
              "market_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlich"])},
              "market_retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelhandel"])},
              "market_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schule"])},
              "market_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassenzimmer"])},
              "market_academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universitäten"])},
              "market_corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
              "catalog_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog type"])},
              "catalog_type_traditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditional publishing"])},
              "catalog_type_self_published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-published"])},
              "catalog_type_public_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public domain"])},
              "catalog_type_open_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Access"])},
              "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
              "from_quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quebec publisher"])}
            }
          }
        }
      }
    }
  })
}
